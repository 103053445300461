import React, { PureComponent } from "react"
import Layout from '../components/layout';
import Disclaimer from '../components/disclaimer';

export default class DisclaimerPage extends PureComponent {
  render() {
    return (
      <Layout>
        <Disclaimer/>
      </Layout>
    )
  }
}
