import React from 'react';

const Disclaimer = () => (
  <article>
        <header>
          <h1>Disclaimer</h1>	</header>
        <div>
          <p>This website is owned by Toni Lynn PhD, Enterprises, and this disclaimer covers the content, media and all other aspects of use for this site, by you. By viewing this website or anything made available on or through this website, including but not limited to programs, products, services, opt-in gifts, e-books, videos, webinars, blog posts, e-newsletters, consultations, e-mails, social media and/or other communication (collectively referred to as “website”), you are agreeing to accept all parts of this Disclaimer. </p>
          <p>If you do not agree to any aspect of this Disclaimer, exit this website and do not access or use this website now or in the future.</p>
          <p><strong>Explicitly for Educational and Informational Purposes.</strong></p>
          <p>The information provided in or through this Website is for educational and informational purposes only. You may choose to use this information as a self-help tool, with the understanding that any subsequent outcome of self-use falls upon you as the consumer and deciding individual. No part(s) of this website is for commercial use.</p>
          <p><strong>No Medical, Mental Health, or Religious Advice.</strong></p>
          <p>I am not, nor am I claiming to assume the position, role, responsibility or expertise of a medical doctor/physician, nurse, physician’s assistant, or any other medical professional/provider/entity (“Medical Professional/Provider”). Although I am a licensed clinical social worker in the state of Pennsylvania, I operate Toni Lynn PhD, Enterprises in the capacity of a coach, and am therefore not practicing as a Mental Health Provider within the context of the coaching relationship. As a Professional Coach, I am not providing health care, medical or nutritional therapy services, or attempting to diagnose, treat, prevent or cure any physical, mental or emotional issue, disease or condition. I am no providing clinical services on this website or via any other aspect of any subsequent coaching-client relationship that may form as a result of your utilizing this website or accessing any of my proprietary content or materials.</p>
          <p>Any and all information provided to, in, by or through my website that may pertain to or influence any aspect of your life is not intended to be a substitute for professional medical advice, diagnosis or treatment provided by your own separate Medical and/or Mental Health Provider. </p>
          <p>I am not providing medical advice, mental health advice, or religious advice in any way, to which you express an agreement to, by continuing to use this website. Prior to implementing any recommendations or suggestions from my website, seek the advice of your own Medical Provider and/or Mental Health Provider regarding any questions or concerns you have about your specific health condition or any medications, herbs or supplements you are currently taking, etc. Do not disregard medical advice or delay seeking medical advice because of information you have read on this website. Do not start or stop taking any medications without speaking to your own Medical Provider or Mental Health Provider. If you have or suspect that you have a medical or mental health problem, contact your own Medical and/or Mental Health Provider promptly. The information contained on this website has not been evaluated by the Food and Drug Administration.</p>
          <p><strong>No Legal, Financial or Other Professional Advice.</strong></p>
          <p>I am not an attorney, accountant or financial advisor, nor am I claiming to assume the position, role, responsibility or expertise of any other professional entity besides a professional life coach, in the context of this website and business. No information on this website is intended to be a substitute for legal, financial, or other expert professional advice. Seek help from your own attorney, accountant, and/or financial advisor for any matters in those areas. </p>
          <p>Although I take intentional care to provide the most up to date, efficacious, and quality information via this website, I cannot be held responsible for any errors or omissions, and I accept no liability whatsoever for any loss or damage you may incur. Always seek financial and/or legal counsel relating to your specific circumstances as needed for any and all questions and concerns you now have, or may have in the future. You agree that the information on our Website is not legal or financial advice.</p>
          <p><strong>Your Personal Responsibility.</strong></p>
          <p>It is your personal responsibility and<strong> </strong>aim to accurately request and/or accurately present the information provided on, to or through this website, and to request information solely as a curious consumer and not in an attempt to intentionally defame or otherwise harm Toni Lynn PhD, Enterprises and any of its/my business, purposes, staff or content. You acknowledge that you are participating voluntarily in using this website and that you are solely and personally responsible for your choices, actions, interpretations and results, both now and in the future. You accept full responsibility for any and all consequences of your use, or non-use, of any information provided on, by, to or through this website. You agree to use your own judgment and due diligence before implementing any idea, suggestion or recommendation from this website to any aspect of your life.</p>
          <p><strong>There Are No Guarantees.</strong></p>
          <p>As a professional life coach, my goal and role is to support and assist you in reaching your own personally identified goals. A core tenet of the coaching model is the belief that clients possess the power and ability within themselves to stimulate and sustain their desired life change. As the coach, I act as a sherpa, or guide, on this adventure of your life journey. I do not make your decisions for you. Therefore, all success or lack thereof, is reliant upon you. Your success or lack thereof will be contingent upon your own efforts, motivation, follow through, and commitment. I do not and cannot predict nor guarantee that you will gain a particular result. You acknowledge, accept and understand that every individual attains different results. Your personal results may be further impacted by your own unique background, dedication, desire, motivation, actions, as well as an invariable amount of other factors. You fully agree, without exception, that there are no guarantees about any specific outcomes resulting from your access or use of the information you interact with on, by, to or through this website.</p>
          <p><strong>Outcomes &amp; Testimonials Disclaimer.</strong></p>
          <p>Any testimonials or examples of others stories that are shared on this website are for illustration of a sample of someone else’s or a conglomeration of multiple others’ stories, and are not intended to be applied to the expectations for your own unique circumstance or outcome. No assurance can be made as to any particular outcome based on the use of this website. You agree that I and Toni Lynn PhD, Enterprises, are not responsible for any result of any kind that you may have as a result of information presented to you through this website. You are solely responsible for your outcomes. Testimonials or any shared stories are not intended to represent or guarantee that current or future clients will achieve the same or similar results. These testimonials represent what is possible for illustrative purposes only.</p>
          <p><strong>Assumption of Risk.</strong></p>
          <p>All risks associated with the use of any online website or information, including this one, can not be known or predicted and you therefore acknowledge, understand, and agree to assume any and all responsibility and accountability for your use of this website and any outcome that subsequently result from that use. You do not hold Toni Lynn PhD, Enterprises or Toni Warner-McIntyre, the founder and owner, as responsible for any unforeseen circumstance that results from your accessing or using any content from this site. Should you access, use, mention or otherwise reference or implement information from any part of my website it is done so at your own risk, with no liability on my or Toni Lynn PhD, Enterprises’s part. You acknowledge and are aware that there could be a rare chance that illness, injury or even death could result from your chosen use of this website, and you agree to assume all risks.</p>
          <p><strong>Limitation of Liability.</strong></p>
          <p>You agree: </p>
          <ul><li>To absolve me of any liability or loss that you or any other person may incur from use of the information, products or materials that you request or receive through or on this website.</li><li>I will not be liable to you, or to any other individual, company or entity, for any type of damages, including direct, indirect, special, incidental, equitable or consequential loss or damages, for use of or reliance on this website. </li><li>I do not assume liability for accidents, delays, injuries, harm, loss, damage, death, lost profits, personal or business interruptions, misapplication of information, physical or mental disease or condition or issue, or any other type of loss or damage due to any act or default by me or anyone acting as our agent, consultant, affiliate, joint venture partner, employee, shareholder, director, staff, team member, or anyone otherwise affiliated with my business or me, who is engaged in delivering content on or through this website.</li></ul>
          <p><strong>Indemnification and Release of Claims.</strong></p>
          <p><em>You hereby fully and completely hold harmless, indemnify and release me and any of my agents, consultants, affiliates, joint venture partners, employees, shareholders, directors, staff, team members, or anyone otherwise affiliated with my business or me from any and all causes of action, allegations, suits, claims, damages, or demands whatsoever, in law or equity, that may arise in the past, present or future that is in any way related to this website.</em></p>
          <p><strong>No Warranties.</strong></p>
          <p>I MAKE:</p>
          <ul><li>NO WARRANTIES RELATED TO THE PERFORMANCE OR OPERATION OF THIS WEBSITE ARE CLAIMED TO BE MADE. </li><li>NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE INFORMATION, CONTENT, MATERIALS, PROGRAMS, PRODUCTS OR SERVICES INCLUDED ON OR THROUGH THE WEBSITE. </li></ul>
          <p>AND TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, I DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
          <p><strong>Errors and Omissions.</strong></p>
          <p>Although I make every effort to ensure the accuracy of information shared on or through this website, the information may inadvertently contain inaccuracies or typographical errors. You agree that I am not responsible for the views, opinions, or accuracy of facts referenced on or through this website, or of those of any other individual or company affiliated with my business or me in any way. Because scientific technology and business practices are constantly changing, you agree that I am not responsible for the accuracy of this website, or for any errors or omissions that may occur.</p>
          <p><strong>No Endorsement.</strong></p>
          <p>Information or links on this website that reference information, opinions, advice, programs, products or services of any other individual, business or entity does not constitute my formal endorsement. I share information that you may choose to access for your own self-help journey, should you choose. I am not responsible for website content, blogs, e-mails, videos, social media, programs, products and/or services of any other person, business or entity that may be linked to or referenced by or on this website. Conversely, should this website link appear in any other individual’s, business’s or entity’s website, program, product or services, it does not constitute my formal endorsement of them, their business or their website.</p>
          <p><strong>Affiliates.</strong></p>
          <p>At my discretion, I may promote, affiliate with, or partner with other individuals or businesses whose programs, products and services align with mine. You are permitted to be aware that there may be instances when I choose to promote, market, share or sell programs, products or services for other partners and I may, in exchange, be compensated financially or via other rewards. Although I am very selective in these circumstances, you agree that any such promotion or marketing does not serve as any form of endorsement whatsoever. You, as the beacon and decision maker of your life, are still required to use your own judgment to determine that any such program, product or service is appropriate for you. You assume all risks, and agree that I am not liable in any way for any program, product or service that I may promote, market, share or sell on or through my Website.</p>
          <p><strong>Contact.</strong></p>
          <p>By using this website you agree to every section of the above Disclaimer. If you have any questions about this Disclaimer, please contact me at hello@drtonicoaches.com<br /></p>
        </div>
      </article>
);

export default Disclaimer;